import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel';
/* import { HashLocationStrategy, LocationStrategy } from '@angular/common'; */

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './about/about.component';
import { ServicesComponent } from './services/services.component';
import { ContactComponent } from './contact/contact.component';
import { FaqComponent } from './faq/faq.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { HomeComponent } from './home/home.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { CostEstimatingComponent } from './services/cost-estimating/cost-estimating.component';
import { QuantityTakeoffsComponent } from './services/quantity-takeoffs/quantity-takeoffs.component';
import { CostAnalysisAndManagementComponent } from './services/cost-analysis-and-management/cost-analysis-and-management.component';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ServicesComponent,
    ContactComponent,
    FaqComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    PagenotfoundComponent,
    CostEstimatingComponent,
    QuantityTakeoffsComponent,
    CostAnalysisAndManagementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    OwlModule
  ],
  providers: [/* {provide: LocationStrategy, useClass: HashLocationStrategy} */],
  bootstrap: [AppComponent]
})
export class AppModule { }
