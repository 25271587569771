import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import * as $ from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {

    $(document).ready(function(){
	    $(window).on("scroll", function() {
        if ($(window).scrollTop() >= 200) {
          $("#mainNav").addClass("header-compressed");
        } else {
          $("#mainNav").removeClass("header-compressed");
        }
      });
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        $("#navbarContent").removeClass("show");
        $("#toggle").removeClass("collapsed");
      }
    });
  }
}
