import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';  
import { ServicesComponent } from './services/services.component';  
import { FaqComponent } from './faq/faq.component';
import { CostEstimatingComponent } from './services/cost-estimating/cost-estimating.component';    
import { QuantityTakeoffsComponent } from './services/quantity-takeoffs/quantity-takeoffs.component';
import { CostAnalysisAndManagementComponent } from './services/cost-analysis-and-management/cost-analysis-and-management.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'services', component: ServicesComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'services/cost-estimating', component: CostEstimatingComponent },
  { path: 'services/quantity-takeoffs', component: QuantityTakeoffsComponent },
  { path: 'services/cost-analysis-and-management', component: CostAnalysisAndManagementComponent },
  { path: '**', component: PagenotfoundComponent },
];




@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
